// @flow
import React from 'react'
import Story from 'storybook/Story'
import { graphql } from 'gatsby'
import Layout from 'containers/Layout'
import styled from 'styled-components'
import Meta from 'theme/atoms/Meta'
import {BannerProvider} from 'hooks/useBanner'

type Props = {
  location: {
    pathname: string
    key: string
  }
  data: {
    page: {
      story: any
      title: string
      breadcrumbTitle?: string
      headline?: string
      metaDescription: string
      metaRobotsNoindex: boolean
    }
    banner: {
      title:string
      objectID:string
      popupCtaLabel:string
      popupCtaUrl:string
      popupDescription:string
      popupHintText:string
      popupTitle:string
      subtitle:string
      backgroundColor: string
    } | null
  }
}

export default (props: Props) => {
  const { page } = props.data
  const { story } = page
  const crumbs = [{ label: 'Startseite', link: '/' }, { label: page.breadcrumbTitle || page.title }]
  return (
    <BannerProvider banner={props.data.banner}>
      <Layout breadcrumbs={crumbs} partialStateUpdates={story.partialStateUpdates}>
        <Meta
          type="article"
          pathname={props.location.pathname}
          title={page.title}
          description={page.metaDescription}
          noFollow={page.metaRobotsNoindex}
        />
        <Headline>{page.headline || page.title}</Headline>
        <Story key={props.location.key} story={story} />
      </Layout>
    </BannerProvider>
  )
}

const Headline = styled.h1`
  text-align: center;
  margin-left: 15px;
  margin-right: 15px;
  color: #262626;
  font-family: 'Cormorant Garamond';
  font-size: 36px;
  font-weight: 500;
  letter-spacing: 0.7px;
  line-height: 48px;
`

export const query = graphql`
  query($urlKey: String!) {
    page(urlKey: { eq: $urlKey }) {
      story
      title
      headline
      breadcrumbTitle
      metaDescription
      metaRobotsNoindex
    }
    banner(displayOnContentPages: {eq: true}) {
      title
      objectID
      popupCtaLabel
      popupCtaUrl
      popupDescription
      popupHintText
      popupTitle
      subtitle
      backgroundColor
    }
  }
`
